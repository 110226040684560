import {LatLngBoundsExpression} from "leaflet";

export const THEMES = {
  LIGHT: 'LIGHT',
};

export const LINK_PRIVACY = "https://www.iubenda.com/privacy-policy/87466855"

export const LINK_COOKIE_POLICY = "https://www.iubenda.com/privacy-policy/87466855/cookie-policy"

export const LINK_TERMS = "https://www.iubenda.com/termini-e-condizioni/87466855"

export const WSS_URL = "wss://hub.retemet.com/mqtt"

export const WSS_CENTRALINE_REAL_TIME = "ws/#"
export const WSS_IDROMETRI = "device/TRF001"
export const dimensioniMarker = 30
export const EXPIRING_TIME_CENTRALINA = 30


export const LIGURIA: LatLngBoundsExpression = [
  [43.6587, 7.5602],
  [44.7412, 10.1365],
]