import type {FC} from 'react';
import {useEffect} from 'react';
import {Link as RouterLink, useLocation, useNavigate} from 'react-router-dom';
import PropTypes from 'prop-types';
import {Theme, useMediaQuery} from '@mui/material';
import {Box, Divider, Drawer} from '@mui/material';
import Logo from '../Logo';
import Scrollbar from '../Scrollbar';
import i18n from "i18next";
import NavSection from "../NavSection";
import {VideoCameraBack,Home} from '@mui/icons-material';
interface DashboardSidebarProps {
  onMobileClose: () => void;
  openMobile: boolean;
}

const sections_user = [
  {
    title: "",
    items: [
      {
        title: i18n.t("overview"),
        path: '/',
        icon: <Home fontSize="small" />
      },
        {
            title: i18n.t("webcam"),
            path: '/webcam',
            icon: <VideoCameraBack fontSize="small" />
        }
    ]
  }
];
const DashboardSidebar: FC<DashboardSidebarProps> = (props) => {
  const { onMobileClose, openMobile } = props;
  const location = useLocation();
  const lgUp = useMediaQuery((theme: Theme) => theme.breakpoints.up('lg'));
  const navigate = useNavigate()
  useEffect(() => {
    if (openMobile && onMobileClose) {
      onMobileClose();
    }
  }, [location.pathname]);

  const content = (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        height: '100%'
      }}
    >
      <Scrollbar options={{ suppressScrollX: true }}>
        <Box
          sx={{
            display: {
              lg: 'none',
              xs: 'flex'
            },
            justifyContent: 'center',
            p: 2
          }}
        >
          <RouterLink to="/">
            <Logo
              sx={{
                height: 40,
                width: 40
              }}
            />
          </RouterLink>
        </Box>

        <Divider />
        <Box sx={{ p: 2 }}>
            {  sections_user.map((section) => (
                <NavSection
                    key={section.title}
                    pathname={location.pathname}
                    sx={{
                        '& + &': {
                            mt: 3
                        }
                    }}
                    {...section}
                />
            ))}
        </Box>
      </Scrollbar>
    </Box>
  );

  if (lgUp) {
    return (
      <Drawer
        anchor="left"
        open
        PaperProps={{
          sx: {
            backgroundColor: 'background.paper',
            height: 'calc(100% - 64px) !important',
            top: '64px !Important',
            width: 280
          }
        }}
        variant="permanent"
      >
        {content}
      </Drawer>
    );
  }

  return (
    <Drawer
      anchor="left"
      onClose={onMobileClose}
      open={openMobile}
      PaperProps={{
        sx: {
          backgroundColor: 'background.paper',
          width: 280
        }
      }}
      variant="temporary"
    >
      {content}
    </Drawer>
  );
};

DashboardSidebar.propTypes = {
  onMobileClose: PropTypes.func,
  openMobile: PropTypes.bool
};

export default DashboardSidebar;
